import React, { FunctionComponent, useEffect, useState } from 'react'

import { DividingLine, Title } from '@toasttab/buffet-pui-config-templates'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { useFeature } from '@toasttab/ec-features'
import { useUser } from '@toasttab/ec-session'
import {
  Maybe,
  PayCardEmployerAccessLevel,
  PayCardPayoutConfiguration,
  PayCardTipsConfiguration
} from '@local/api/generated/gql/graphql'
import { SettingsTipsToggle } from '../SettingsTipsToggle'
import {
  useGetCustomerAccess,
  useGetCustomerSettings,
  useGetLastEvent,
  useUpdateCustomerSettings
} from '@local/api/graphql'
import { t } from '@local/translations'
import { AuditSummary } from '../audit'
import { LoadingErrorWrapper } from '../LoadingErrorWrapper'
import { PayCardFeatureFlags } from '../../shared/featureFlags'
import { SettingsEmptyState } from '../SettingsEmptyState'
import { SettingsPageFooter } from '../SettingsPageFooter'
import { SettingsPayoutEnabledToggle } from '../SettingsPayoutEnabledToggle'
import { SettingsPayoutToggle } from '../SettingsPayoutToggle'

export const SettingsPageBaseCard: FunctionComponent = () => {
  const user = useUser()
  const { payCardCustomerSettings, loading, error } = useGetCustomerSettings()
  const {
    content: payCardCustomerAccess,
    loading: customerAccessLoading,
    error: customerAccessError
  } = useGetCustomerAccess()
  const [payCardCustomerAccessLevel, setPayCardCustomerAccessLevel] =
    useState<PayCardEmployerAccessLevel>()

  const lastEventResponse = useGetLastEvent()
  const { updateCustomerSettingsMutation } = useUpdateCustomerSettings()

  const [adminPayoutsLocked, setAdminPayoutsLocked] = useState<boolean>(false)
  const [payoutEnabled, setPayoutEnabled] = useState<boolean>(false)
  const [payoutConfiguration, setPayoutConfiguration] = useState<
    Maybe<PayCardPayoutConfiguration>
  >(
    payCardCustomerSettings?.payCardPayoutConfiguration
      ? payCardCustomerSettings.payCardPayoutConfiguration
      : null
  )
  const [tipsConfiguration, setTipsConfiguration] = useState<
    Maybe<PayCardTipsConfiguration>
  >(
    payCardCustomerSettings?.payCardTipsConfiguration
      ? payCardCustomerSettings.payCardTipsConfiguration
      : null
  )
  const useCustomerSettingsV2 = useFeature(
    PayCardFeatureFlags.USE_CUSTOMER_SETTINGS_V2
  )

  function setCustomerAccessLevel() {
    const restaurants = []

    payCardCustomerAccess?.payCardCustomerAccess?.businesses?.forEach(
      (business) => {
        restaurants.push(...(business.restaurants || []))
      }
    )

    restaurants.push(
      ...(payCardCustomerAccess?.payCardCustomerAccess?.detachedRestaurants ||
        [])
    )

    let payCardAccessLevel

    // If any restaurants associated with a customer do not have the pay card entitlement we want to return none
    // However we also need to keep track of onboarding restaurants and return onboarding if
    // any of those are on the customer
    restaurants.forEach((restaurant) => {
      if (restaurant.accessLevel === PayCardEmployerAccessLevel.NONE) {
        payCardAccessLevel = PayCardEmployerAccessLevel.NONE
        return
      } else if (
        restaurant.accessLevel === PayCardEmployerAccessLevel.ONBOARDING
      ) {
        payCardAccessLevel = PayCardEmployerAccessLevel.ONBOARDING
      }
    })

    if (payCardAccessLevel) {
      setPayCardCustomerAccessLevel(payCardAccessLevel)
    }
  }

  useEffect(() => {
    if (!customerAccessLoading && payCardCustomerAccess) {
      setCustomerAccessLevel()
    }
  }, [payCardCustomerAccess])

  useEffect(() => {
    if (payCardCustomerSettings?.payCardPayoutEnabled) {
      setPayoutEnabled(payCardCustomerSettings.payCardPayoutEnabled)
    }
    if (payCardCustomerSettings?.payCardTipsConfiguration) {
      setTipsConfiguration(payCardCustomerSettings.payCardTipsConfiguration)
    }
    if (payCardCustomerSettings?.payCardPayoutConfiguration) {
      setPayoutConfiguration(payCardCustomerSettings.payCardPayoutConfiguration)
    }
    if (
      payCardCustomerSettings?.payCardAdminSettings?.payCardAdminPayoutsLocked
    ) {
      setAdminPayoutsLocked(
        payCardCustomerSettings.payCardAdminSettings.payCardAdminPayoutsLocked
      )
    }
  }, [payCardCustomerSettings])

  useEffect(() => {
    lastEventResponse.refetch()
  }, [payoutEnabled, payoutConfiguration, tipsConfiguration, lastEventResponse])

  const onClickHandlerPayoutEnabled = () => {
    updateCustomerSettingsMutation({
      variables: {
        input: {
          payCardPayoutEnabled: !payoutEnabled,
          payCardTipsConfiguration: null,
          payCardPayoutConfiguration: null,
          payCardAdminSettings: null
        }
      }
    }).catch((error: any) => {
      console.error('update pay card customer settings mutation error: ', error)
    })

    setPayoutEnabled((previous: boolean) => !previous)
  }

  const onClickHandlerPayoutConfig = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let selectedPayoutConfiguration = e.target
      .value as PayCardPayoutConfiguration

    updateCustomerSettingsMutation({
      variables: {
        input: {
          payCardPayoutConfiguration: selectedPayoutConfiguration,
          payCardPayoutEnabled: null,
          payCardTipsConfiguration: null,
          payCardAdminSettings: null
        }
      }
    }).catch((error: any) => {
      console.error('update pay card customer settings mutation error: ', error)
    })

    setPayoutConfiguration(selectedPayoutConfiguration)
  }

  const onClickHandlerTipsConfig = (e: React.ChangeEvent<HTMLInputElement>) => {
    let selectedTipsConfiguration = e.target.value as PayCardTipsConfiguration
    updateCustomerSettingsMutation({
      variables: {
        input: {
          payCardTipsConfiguration: selectedTipsConfiguration,
          payCardPayoutEnabled: null,
          payCardPayoutConfiguration: null,
          payCardAdminSettings: null
        }
      }
    }).catch((error: any) => {
      console.error('update pay card customer settings mutation error: ', error)
    })

    setTipsConfiguration(selectedTipsConfiguration)
  }

  function renderPayrollBouncedAdminNotice() {
    if (
      adminPayoutsLocked &&
      (user.isUserSuperAdminLight || user.isUserSuperAdmin)
    ) {
      return (
        <Alert className='mt-0' outlined>
          <p className='mt-1 type-subhead'>
            PayOuts are disabled due to bounced payroll
          </p>
        </Alert>
      )
    } else return null
  }

  function renderPayoutEnabledToggle() {
    return (
      <SettingsPayoutEnabledToggle
        onClickHandlerPayoutEnabled={onClickHandlerPayoutEnabled}
        isActive={payoutEnabled}
        adminPayoutsLocked={adminPayoutsLocked}
      />
    )
  }

  function renderPayoutConfigToggle() {
    if (!adminPayoutsLocked && payoutEnabled && payoutConfiguration) {
      return (
        <SettingsPayoutToggle
          onClickHandlerPayoutConfig={onClickHandlerPayoutConfig}
          currentPayoutConfiguration={payoutConfiguration}
        />
      )
    } else return null
  }

  function renderTipsConfigToggleV1() {
    if (tipsConfiguration) {
      return (
        <SettingsTipsToggle
          onClickHandlerTipsConfig={onClickHandlerTipsConfig}
          currentTipsConfiguration={tipsConfiguration}
          currentPayoutConfiguration={PayCardPayoutConfiguration.WAGES_AND_TIPS}
        />
      )
    } else return null
  }

  function renderTipsConfigToggle() {
    if (
      !adminPayoutsLocked &&
      payoutEnabled &&
      tipsConfiguration &&
      payoutConfiguration &&
      payoutConfiguration !== PayCardPayoutConfiguration.WAGES_ONLY
    ) {
      return (
        <>
          <CardContainer className='flex'>
            <SettingsTipsToggle
              onClickHandlerTipsConfig={onClickHandlerTipsConfig}
              currentTipsConfiguration={tipsConfiguration}
              currentPayoutConfiguration={payoutConfiguration}
            />
          </CardContainer>
        </>
      )
    } else return null
  }

  const isPayCardEnabled = !(
    payCardCustomerAccessLevel === PayCardEmployerAccessLevel.NONE ||
    payCardCustomerAccessLevel === PayCardEmployerAccessLevel.ONBOARDING
  )

  return (
    <div className='max-w-3xl'>
      <Title>{t('page.title')}</Title>
      <DividingLine />
      <LoadingErrorWrapper
        // Apollo has a known bug where loading is set to false but the query response data is undefined. This requires us to check that payCardCustomerSettings is not undefined before rendering the page.
        // This also means we need to check for errors here to show the corresponding error state screen for customers that are not contracted.
        isLoading={
          (loading || !payCardCustomerSettings || !payCardCustomerAccess) &&
          !error &&
          customerAccessLoading
        }
        error={error && customerAccessError}
      >
        {!isPayCardEnabled ? (
          <SettingsEmptyState
            incompleteContract={
              payCardCustomerAccessLevel ===
              PayCardEmployerAccessLevel.ONBOARDING
            }
          />
        ) : (
          <div className='flex flex-col'>
            <AuditSummary lastEventResponse={lastEventResponse} />
            {useCustomerSettingsV2 ? (
              <>
                <CardContainer>
                  <div className='flex justify-between'>
                    <p
                      className='font-bold mt-4 text-default type-headline-5'
                      data-toast-education-id='paycard-customer-settings-title'
                    >
                      {t('pay-out-panel.title')}
                    </p>
                    {renderPayrollBouncedAdminNotice()}
                  </div>
                  {renderPayoutEnabledToggle()}
                  {renderPayoutConfigToggle()}
                </CardContainer>
                {renderTipsConfigToggle()}
              </>
            ) : (
              <CardContainer>
                <p className='font-bold text-default type-headline-5 mt-4 mb-8'>
                  {t('pay-out-panel.title')}
                </p>
                {renderTipsConfigToggleV1()}
              </CardContainer>
            )}
          </div>
        )}
        <SettingsPageFooter isPayCardEnabled={isPayCardEnabled} />
      </LoadingErrorWrapper>
    </div>
  )
}
