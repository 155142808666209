import React, { FunctionComponent } from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { useMediaQuery } from '@toasttab/buffet-use-media-query'
import { t } from '@local/translations'
import { PATTERN_BACKGROUND, TOAST_CARDS_IMAGE_CROPPED_URL } from '../constants'

type SettingsEmptyStateProps = {
  incompleteContract?: boolean
}

const TOAST_SHOP_LINK = 'https://pos.toasttab.com/pay-card'
const TOAST_CENTRAL_LINK =
  'https://central.toasttab.com/s/article/Toast-Payroll-Toast-Pay-Card-Employer-Guide-Getting-Started#getting_started'

export const SettingsEmptyState: FunctionComponent<SettingsEmptyStateProps> = ({
  incompleteContract = false
}) => {
  const isMobile = useMediaQuery('(max-width: 768px)')

  return (
    <CardContainer noPadding className='flex flex-row'>
      <div
        className={
          // adapted from @toasttab/buffet-pui-card paddingStyles
          'flex flex-col md:w-full md:pt-6 md:pl-8 md:pb-8 pt-4 pl-4 pb-6 pr-0'
        }
      >
        <h3 className='font-semibold text-default type-large md:type-large'>
          {t('empty-state.title')}
        </h3>
        <p className='mt-3 md:mt-4 text-secondary type-default md:type-default'>
          {t('empty-state.description')}
        </p>
        <div className='mt-3'>
          <Button
            as='a'
            href={incompleteContract ? TOAST_CENTRAL_LINK : TOAST_SHOP_LINK}
            className='w-full md:w-auto'
            size={isMobile ? 'lg' : 'sm'}
            variant='primary'
            target='_blank'
            rel='noopener noreferrer'
          >
            {t('empty-state.button')}
          </Button>
        </div>
      </div>
      <img
        className='h-56 md:w-full mt-5'
        src={TOAST_CARDS_IMAGE_CROPPED_URL}
        alt={t('empty-state.image')}
      />
      <img
        className='w-72 md:w-full'
        src={PATTERN_BACKGROUND}
        alt={t('empty-state.background')}
      />
    </CardContainer>
  )
}
