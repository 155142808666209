import { useMutation, useQuery } from '@apollo/client'
import {
  Maybe,
  PayCardCustomerSettingsLastEventQuery,
  PayCardCustomerSettingsAuditHistoryQuery,
  PayCardCustomerSettingsQuery,
  UpdatePayCardCustomerSettingsMutation,
  UpdatePayCardCustomerSettingsMutationVariables,
  SimplePayCardCustomerAccessQuery
} from '../generated/gql/graphql'
import {
  AUDIT_HISTORY,
  CUSTOMER_ACCESS,
  CUSTOMER_SETTINGS,
  LAST_EVENT,
  UPDATE_CUSTOMER_SETTINGS
} from '../documents'
import { FetchResult } from '@apollo/client/link/core'
import { parseApolloError, SdpGraphQLError } from './sdpGraphqlError'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import {
  PayCardCustomerSettings,
  PayCardCustomerSettingsAuditEntry,
  UpdatePayCardCustomerSettingsResponse
} from '../generated'
import { t } from '@local/translations'

interface UseGetCustomerSettingsResponse {
  payCardCustomerSettings: Maybe<PayCardCustomerSettings>
  loading: boolean
  error: Maybe<SdpGraphQLError>
}

export const useGetCustomerSettings = (): UseGetCustomerSettingsResponse => {
  const {
    data: response,
    loading,
    error
  } = useQuery<PayCardCustomerSettingsQuery>(CUSTOMER_SETTINGS, {
    fetchPolicy: 'network-only'
  })

  let payCardCustomerSettings: Maybe<PayCardCustomerSettings> =
    response?.payCardCustomerSettings || null

  return {
    payCardCustomerSettings,
    loading,
    error: parseApolloError(error)
  }
}

interface UseUpdateCustomerSettingsResponse {
  updateCustomerSettingsMutation: (options: {
    variables: UpdatePayCardCustomerSettingsMutationVariables
  }) => Promise<FetchResult<UpdatePayCardCustomerSettingsMutation>>
  updateCustomerSettingsData: Maybe<UpdatePayCardCustomerSettingsResponse>
  loading: boolean
  error: Maybe<SdpGraphQLError>
}

export const useUpdateCustomerSettings =
  (): UseUpdateCustomerSettingsResponse => {
    const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()

    const [
      updateCustomerSettingsMutation,
      { data: updateCustomerSettingsResponse, loading, error }
    ] = useMutation<UpdatePayCardCustomerSettingsMutation>(
      UPDATE_CUSTOMER_SETTINGS,
      {
        onCompleted: () => {
          showSuccessSnackBar(t('settings-update.success'), {
            isDismissable: true
          })
        },
        onError: () => {
          showErrorSnackBar(t('settings-update.failure'))
        },
        refetchQueries: [{ query: CUSTOMER_SETTINGS }]
      }
    )

    let updateCustomerSettingsData: Maybe<UpdatePayCardCustomerSettingsResponse> =
      null
    if (updateCustomerSettingsResponse) {
      updateCustomerSettingsData =
        updateCustomerSettingsResponse.updatePayCardCustomerSettings
    }

    return {
      updateCustomerSettingsMutation,
      updateCustomerSettingsData,
      loading,
      error: parseApolloError(error)
    }
  }

interface UseGetCustomerSettingsAuditHistoryResponse {
  payCardCustomerSettingsAuditHistory: Array<PayCardCustomerSettingsAuditEntry>
  loading: boolean
  error: Maybe<SdpGraphQLError>
}

export const useGetCustomerSettingsAuditHistory =
  (): UseGetCustomerSettingsAuditHistoryResponse => {
    const {
      data: response,
      loading,
      error
    } = useQuery<PayCardCustomerSettingsAuditHistoryQuery>(AUDIT_HISTORY, {
      fetchPolicy: 'network-only'
    })

    let payCardCustomerSettingsAuditHistory: Array<PayCardCustomerSettingsAuditEntry> =
      response?.payCardCustomerSettingsAuditHistory || []

    return {
      payCardCustomerSettingsAuditHistory,
      loading,
      error: parseApolloError(error)
    }
  }

export interface UseGetLastEventResponse {
  lastEvent: Maybe<PayCardCustomerSettingsAuditEntry>
  loading: boolean
  error: Maybe<SdpGraphQLError>
  refetch: Function
}

export const useGetLastEvent = (): UseGetLastEventResponse => {
  const {
    data: response,
    loading,
    error,
    refetch
  } = useQuery<PayCardCustomerSettingsLastEventQuery>(LAST_EVENT)

  let lastEvent: Maybe<PayCardCustomerSettingsAuditEntry> =
    response?.payCardCustomerSettingsLastEvent || null

  return {
    lastEvent,
    loading,
    error: parseApolloError(error),
    refetch
  }
}

interface UseGetCustomerAccessResponse {
  content: Maybe<SimplePayCardCustomerAccessQuery>
  loading: boolean
  error: Maybe<SdpGraphQLError>
}

export const useGetCustomerAccess = (): UseGetCustomerAccessResponse => {
  const {
    data: queryResponse,
    loading,
    error
  } = useQuery<SimplePayCardCustomerAccessQuery>(CUSTOMER_ACCESS, {
    variables: {
      input: {
        skipCache: false
      }
    },
    fetchPolicy: 'network-only'
  })

  let payCardCustomerAccess: Maybe<SimplePayCardCustomerAccessQuery> =
    (queryResponse as SimplePayCardCustomerAccessQuery) || null

  return {
    content: payCardCustomerAccess,
    loading,
    error: parseApolloError(error)
  }
}
