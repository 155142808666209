import React, { FunctionComponent } from 'react'
import { CardSelectorGroup } from '@toasttab/buffet-pui-card-selector'
import { Alert } from '@toasttab/buffet-pui-alerts'

import {
  Maybe,
  PayCardPayoutConfiguration,
  PayCardTipsConfiguration
} from '@local/api/generated/gql/graphql'
import { t, Trans } from '@local/translations'

type SettingsTipsToggleProps = {
  currentTipsConfiguration: Maybe<PayCardTipsConfiguration>
  currentPayoutConfiguration: Maybe<PayCardPayoutConfiguration>
  onClickHandlerTipsConfig: Function
}

export const SettingsTipsToggle: FunctionComponent<SettingsTipsToggleProps> = ({
  currentTipsConfiguration,
  currentPayoutConfiguration,
  onClickHandlerTipsConfig
}) => {
  return (
    <div className='flex flex-col'>
      <h3 className='font-semibold text-default type-default md:type-default mt-9 md:mt-11'>
        {t('tax-panel.title')}
      </h3>
      <p className='mt-3 text-secondary md:mt-5 type-default md:type-default'>
        {t('tax-panel.subtitle')}
      </p>
      <div>
        <CardSelectorGroup
          itemsContainerClassName='grid-cols-2 mt-5'
          onChange={(e) => {
            onClickHandlerTipsConfig(e)
          }}
          options={[
            {
              contents: (
                <div>
                  <p className='font-semibold text-default'>
                    {t('tax-panel.adjust.title')}
                  </p>
                  <p className='text-secondary'>
                    {t('tax-panel.adjust.description')}
                  </p>
                </div>
              ),
              value: PayCardTipsConfiguration.TIPS_WITH_WITHHOLDING,
              checked:
                currentTipsConfiguration ===
                PayCardTipsConfiguration.TIPS_WITH_WITHHOLDING
            },
            {
              contents: (
                <div>
                  <p className='font-semibold text-default'>
                    {t('tax-panel.do-not-adjust.title')}
                  </p>
                  <p className='text-secondary'>
                    <Trans i18nKey='tax-panel.do-not-adjust.description' />
                  </p>
                </div>
              ),
              value: PayCardTipsConfiguration.TIPS_ALL,
              checked:
                currentTipsConfiguration === PayCardTipsConfiguration.TIPS_ALL
            }
          ]}
        />
      </div>
      {currentPayoutConfiguration === PayCardPayoutConfiguration.TIPS_ONLY ? (
        <Alert className='mt-5' outlined>
          <p className='type-subhead'>{t('tax-panel.tips-warning')}</p>
        </Alert>
      ) : null}

      {currentPayoutConfiguration ===
      PayCardPayoutConfiguration.WAGES_AND_TIPS ? (
        <Alert className='mt-5' outlined>
          <p className='type-subhead'>
            {t('tax-panel.wages-and-tips-warning')}
          </p>
        </Alert>
      ) : null}
    </div>
  )
}
