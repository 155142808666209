import i18next from 'i18next'
import { Locale } from '@toasttab/buffet-pui-types'
import { format as formatDate } from '@toasttab/buffet-pui-date-utilities'

const DEFAULT_LOCALE = 'en-US'

export { i18next }

export const initI18Next = (language?: Locale) => {
  return i18next.init({
    lng: language || DEFAULT_LOCALE,
    fallbackLng: DEFAULT_LOCALE,
    interpolation: {
      escapeValue: false,
      format: (value, format) => {
        if (value instanceof Date) {
          return formatDate(value, format)
        }
        return value
      }
    },
    resources: {}
  })
}

export const changeLanguage = (language: Locale) => {
  if (!i18next.language) {
    initI18Next()
  }
  i18next.changeLanguage(language)
}

export const hasResourceBundle: typeof i18next.hasResourceBundle = (
  ...props
) => {
  if (!i18next.language) {
    initI18Next()
  }
  return i18next.hasResourceBundle(...props)
}

type AddResources = typeof i18next.addResources & {
  overWrite?: boolean
}

export const addResources: AddResources = (
  lng,
  ns,
  resources,
  overWrite = false
) => {
  if (!i18next.language) {
    initI18Next()
  }

  if (!resources) {
    return i18next
  }

  if (i18next.hasResourceBundle(lng, ns) && !overWrite) {
    return i18next
  }

  const flattenResources = (
    resourceObj: Record<string, any>
  ): Record<string, string> => {
    const workingResources: Record<string, string> = {}

    const traverse = (obj: Record<string, any>, path: string[] = []) => {
      for (const [key, value] of Object.entries(obj)) {
        const newPath = [...path, key]
        if (typeof value === 'string') {
          workingResources[newPath.join('.')] = value
        } else if (typeof value === 'object' && 'message' in value) {
          workingResources[newPath.join('.')] = value.message
        } else if (typeof value === 'object') {
          traverse(value, newPath)
        }
      }
    }

    traverse(resourceObj)

    return workingResources
  }

  const flattenedResources = flattenResources(resources)

  return i18next.addResources(lng, ns, flattenedResources)
}

/**
 * createLoadStrings - Creates a function that loads strings for a given namespace. For use when all available locales are loaded into a locales variable.
 * @param namespace - The namespace for which to load strings.
 * @param defaultStrings - The default (en-US) strings for the namespace.
 * @param locales - The locales for which to load strings.
 * @returns {Function} A function that loads strings for a given locale.
 */
export const createLoadStrings = (
  namespace: string,
  defaultStrings: Record<string, any>,
  locales: Partial<Record<Locale, Record<string, any>>>
) => {
  return (locale?: Locale) => {
    if (!locale) {
      locale = (i18next.language as Locale) ?? DEFAULT_LOCALE
    }
    // Load defaults (will be a fallback if necessary)
    if (!hasResourceBundle(DEFAULT_LOCALE, namespace)) {
      addResources(DEFAULT_LOCALE, namespace, defaultStrings)
    }

    // Load locale file if bundle for language not yet loaded
    if (!hasResourceBundle(locale, namespace)) {
      addResources(locale, namespace, locales[locale])
    }
  }
}
