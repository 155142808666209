import React, { FunctionComponent } from 'react'
import { SmartLink } from '../SmartLink'
import cx from 'classnames'
import { useFeature } from '@toasttab/ec-features'
import { t, Trans } from '@local/translations'
import { PayCardFeatureFlags } from '../../shared/featureFlags'

type Props = {
  isPayCardEnabled: boolean
}

export const SettingsPageFooter: FunctionComponent<Props> = ({
  isPayCardEnabled
}) => {
  const useCustomerSettingsV2 = useFeature(
    PayCardFeatureFlags.USE_CUSTOMER_SETTINGS_V2
  )
  return (
    <footer
      className={cx(
        'bg-white md:bg-transparent',
        'bottom-0 md:bottom-auto',
        'p-4 md:px-0 md:mt-5',
        'w-full z-40'
      )}
    >
      <div className='text-secondary type-caption'>
        <p className='text-center'>
          <Trans
            i18nKey='pay-card-disclaimer'
            components={{
              payCardAvailabilityLink: (
                <SmartLink
                  className='text-secondary font-semibold underline'
                  pathname='https://pos.toasttab.com/products/toast-pay-card-and-pay-out/#availability'
                  isExternal={true}
                  isNewTab={true}
                >
                  not available in all jurisdictions
                </SmartLink>
              ),
              callLink: (
                <SmartLink
                  className='text-secondary font-semibold underline'
                  pathname='tel:(617) 682-0225'
                  isExternal={true}
                >
                  (617) 682-0225
                </SmartLink>
              )
            }}
          />
        </p>
        {useCustomerSettingsV2 && isPayCardEnabled && (
          <p className='text-center'>{t('tip-pool-disclaimer')}</p>
        )}
      </div>
    </footer>
  )
}
