import * as React from 'react'
import { useGetCustomerSettingsAuditHistory } from '@local/api/graphql'
import { formatDate, formatTime } from '../../../helpers/dateFormatter'
import { mapMessageKeyToDescription } from '../../../helpers/messageKeyMapper'
import { LoadingWrapperModal } from '../../LoadingWrapperModal'

export interface AuditHistoryProps {
  testId?: string | number
}

export const AuditHistory = (props: AuditHistoryProps) => {
  const { payCardCustomerSettingsAuditHistory, loading } =
    useGetCustomerSettingsAuditHistory()
  return (
    <LoadingWrapperModal isLoading={loading}>
      <div data-testid={props.testId} className='flex flex-col mt-2'>
        {payCardCustomerSettingsAuditHistory.map((entry, idx) => {
          let auditEvent = mapMessageKeyToDescription(entry.change.messageKey)

          return (
            <div
              key={idx}
              className='flex flex-col pt-4 pb-0 gap-4 items-stretch'
            >
              <div className='flex type-overline gap-4'>
                <div>{formatDate(entry.datetime)}</div>
                <div>{formatTime(entry.datetime)}</div>
              </div>
              <div className='text-default'>
                {entry.user.displayName} {auditEvent?.at(0)}{' '}
                <span className='font-semibold'>{auditEvent?.at(1)}</span>
              </div>
              <hr className='bg-darken-12 h-px border-0 p-0 my-1 w-full'></hr>
            </div>
          )
        })}
      </div>
    </LoadingWrapperModal>
  )
}
