import React from 'react'
import i18next, { TOptions } from 'i18next'
import { Trans as TransI18Next } from 'react-i18next'
import {
  Bundle,
  CustomTFunction,
  TransWithNameSpaceProps,
  ObjectWithValidKeys
} from '../types'
import IntlMessageFormat from 'intl-messageformat'

type CustomTranslationMethods<T extends Bundle> = {
  t: CustomTFunction<T, ObjectWithValidKeys<T>>
  Trans: React.FC<TransWithNameSpaceProps>
}

/**
 * containsLogic - Checks if the input string contains any ICU logic (variables, etc)
 * @param input - The string to check
 * @returns - A boolean indicating whether the input string contains any ICU logic
 */
const containsLogic = (input: string) => {
  return input?.match(/{.*}/) || false
}

/**
 * Namespaced T function: Returns a t function, namespaced and Typescript-safe
 * @param {string} ns - A namespace under which the translation keys have been bundled. Usually SPA name from package.json
 */
export const namespacedTFunction = <T extends Bundle>(
  ns: string
): CustomTFunction<T, ObjectWithValidKeys<T>> => {
  return (key: string, val?: TOptions | string, options?: TOptions) => {
    let result = ''
    if (typeof val === 'object') {
      result = i18next.t(key, { ns, ...val })
    } else if (typeof val === 'string') {
      result = i18next.t(key, val, { ns, ...options })
    } else {
      result = i18next.t(key, { ns, ...options })
    }

    // Apply ICU formatter in cases where `{something}` is left in the string
    if (containsLogic(result)) {
      try {
        const formatter = new IntlMessageFormat(
          result,
          i18next.language,
          undefined,
          {
            ignoreTag: true // We process tags in Trans once this processes ICU logic first
          }
        )
        if (typeof val === 'object') {
          result = formatter.format(val) as string
        } else {
          result = formatter.format() as string
        }
      } catch (error) {
        let errorText =
          error instanceof Error && error.message === 'MALFORMED_ARGUMENT'
            ? 'Missing variables used in key (MALFORMED_ARGUMENT)'
            : error
        console.error(`Error for translation key: "${key}"`, errorText)
        return result
      }
    }

    return result
  }
}

/**
 * Namespaced Trans component: Returns a Trans component (from react-i18next), namespaced and Typescript-safe
 * @param {string} ns - A namespace under which the translation keys have been bundled. Usually SPA name from package.json
 */
export const namespacedTransComponent =
  (ns: string): React.FC<TransWithNameSpaceProps> =>
  (transProps: TransWithNameSpaceProps) => {
    // Apply t function first as this will catch any ICU logic
    const t = namespacedTFunction(ns)
    const { i18nKey, children, ...restTransProps } = transProps
    const translatedText = i18nKey
      ? t(i18nKey, {
          ...restTransProps,
          ...restTransProps.values
        })
      : children

    // Pass the translated text as children so that TransI18Next can use as a starting point
    // translatedText === transProps.i18nKey means the key wasn't found so will use fallback
    return (
      <TransI18Next i18n={i18next} ns={ns} {...restTransProps}>
        {translatedText === i18nKey ? children : translatedText}
      </TransI18Next>
    )
  }

/**
 * Custom Translation Methods: Returns a namespaced and Typescript-safe T and Trans function
 * @param {string} ns - A namespace under which the translation keys have been bundled. Usually SPA name from package.json
 */
export const customTranslationMethods = <T extends Bundle>(
  ns: string
): CustomTranslationMethods<T> => {
  return {
    t: namespacedTFunction<T>(ns),
    Trans: namespacedTransComponent(ns)
  }
}
