import React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import {
  Permissions403DesktopIllustration,
  Permissions403MobileIllustration
} from '@toasttab/buffet-pui-illustrations'
import {
  ErrorContainer,
  ErrorHeading,
  ErrorSubheading,
  ErrorCallToActionSection,
  ErrorText
} from '../Error'
import { t, Trans, loadStrings } from '../defaultStrings'

export function ErrorPage403({ testId = 'error-page-403' }) {
  loadStrings()
  return (
    <ErrorContainer>
      <div data-testid={testId}>
        <div
          className='hidden md:block visible mx-auto'
          style={{ maxWidth: 600 }}
        >
          <Permissions403DesktopIllustration
            accessibility='semantic'
            title={t('403-error')}
          />
        </div>
        <div
          className='block md:hidden visible mx-auto'
          style={{ maxWidth: 250 }}
        >
          <Permissions403MobileIllustration resizeToContainer />
        </div>
      </div>
      <div>
        <ErrorHeading>{t('someone-needs-to-unlock-this')}</ErrorHeading>
        <ErrorSubheading>
          {t('please-contact-your-restaurant-administrator')}
        </ErrorSubheading>
        <ErrorSubheading>
          <Trans
            i18nKey='are-you-the-administrator-please'
            components={{
              Link: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  className='inline-link'
                  href='https://central.toasttab.com/s/contact-support'
                  test-id={`${testId}-customer-care`}
                />
              )
            }}
          />
        </ErrorSubheading>
      </div>
      <ErrorCallToActionSection spacing='flex flex-col items-center space-y-4 md:space-y-0 md:space-x-4'>
        <Button
          variant='primary'
          onClick={() => window.history.back()}
          testId={`${testId}-go-back`}
        >
          {t('button.go-back')}
        </Button>
      </ErrorCallToActionSection>
      <div className='space-y-2'>
        <ErrorText>{t('403-access-forbidden')}</ErrorText>
      </div>
    </ErrorContainer>
  )
}
