import React, { FunctionComponent } from 'react'
import { CardSelectorGroup } from '@toasttab/buffet-pui-card-selector'
import { Alert } from '@toasttab/buffet-pui-alerts'
import {
  Maybe,
  PayCardPayoutConfiguration
} from '@local/api/generated/gql/graphql'
import { t } from '@local/translations'

type SettingsPayoutToggleProps = {
  currentPayoutConfiguration: Maybe<PayCardPayoutConfiguration>
  onClickHandlerPayoutConfig: Function
}

export const SettingsPayoutToggle: FunctionComponent<
  SettingsPayoutToggleProps
> = ({ currentPayoutConfiguration, onClickHandlerPayoutConfig }) => {
  const body = (
    <div className='flex flex-col'>
      <h3 className='font-semibold text-default type-default md:type-default mt-9 md:mt-11'>
        {t('pay-out-panel.offers')}
      </h3>
      <div>
        <CardSelectorGroup
          itemsContainerClassName='grid-cols-3 mt-4'
          onChange={(e) => {
            onClickHandlerPayoutConfig(e)
          }}
          options={[
            {
              contents: (
                <div>
                  <p className='text-secondary'>
                    {t('pay-out-panel.offers-option.wages-and-tips')}
                  </p>
                </div>
              ),
              value: PayCardPayoutConfiguration.WAGES_AND_TIPS,
              checked:
                currentPayoutConfiguration ===
                PayCardPayoutConfiguration.WAGES_AND_TIPS
            },
            {
              contents: (
                <div>
                  <p className='text-secondary'>
                    {t('pay-out-panel.offers-option.wages')}
                  </p>
                </div>
              ),
              value: PayCardPayoutConfiguration.WAGES_ONLY,
              checked:
                currentPayoutConfiguration ===
                PayCardPayoutConfiguration.WAGES_ONLY
            },
            {
              contents: (
                <div>
                  <p className='text-secondary'>
                    {t('pay-out-panel.offers-option.tips')}
                  </p>
                </div>
              ),
              value: PayCardPayoutConfiguration.TIPS_ONLY,
              checked:
                currentPayoutConfiguration ===
                PayCardPayoutConfiguration.TIPS_ONLY
            }
          ]}
        />
      </div>
      {currentPayoutConfiguration === PayCardPayoutConfiguration.WAGES_ONLY ? (
        <Alert className='mt-6' outlined>
          <p className='type-subhead'>{t('pay-out-panel.wages-warning')}</p>
        </Alert>
      ) : null}
    </div>
  )
  return <>{body}</>
}
